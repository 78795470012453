







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Message extends Vue {
  @Prop() private type?: string

  get classes() {
    const classes = []

    if (this.type) {
      classes.push(`_${this.type}`)
    }

    return classes
  }
}
