















import { Component, Prop, Vue } from 'vue-property-decorator'
import TimeString from '@/components/TimeString.vue'

@Component({
  components: { TimeString },
})
export default class ListItemTime extends Vue {
  @Prop() time!: {
    id?: string
    label: string
    datetime: string
    display?: string
  }

  get id() {
    return this.time.id
  }

  get href() {
    return !this.id ? null : { name: 'Single', params: { id: this.id } }
  }

  get tag() {
    return this.href ? 'router-link' : 'div'
  }

  get timeLabel() {
    return this.time.label
  }
}
