
































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class FormBlockInput extends Vue {
  @Prop() id?: string
  @Prop() value?: string
  @Prop() options?: {
    label: string
    value: string
  }[]

  get listeners() {
    return {
      ...this.$listeners,
      input: (e: any) => this.$emit('input', e.target.value),
    }
  }
}
