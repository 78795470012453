





































import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { formatDistanceStrict } from 'date-fns'
import Logo from '@/components/Logo.vue'
import ListItemTime from '@/components/ListItemTime.vue'

@Component({
  components: { Logo, ListItemTime },
  filters: {
    fromNow: (date: string) => {
      return formatDistanceStrict(new Date(date), new Date(), {
        addSuffix: true,
      })
    },
  },
  computed: mapState(['items']),
})
export default class ItemList extends Vue {
  items!: any

  sortByOldestLast: boolean = true

  sortTimes(a: any, b: any) {
    const aTime = new Date(a.datetime).getTime()
    const bTime = new Date(b.datetime).getTime()
    return this.sortByOldestLast ? bTime - aTime : aTime - bTime
  }

  get times() {
    return this.items.sort(this.sortTimes)
  }

  onClickDemo() {
    this.$store.commit('pushDemoItems')
  }
}
