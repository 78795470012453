























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Logo extends Vue {
  @Prop({ default: false, type: Boolean }) type!: boolean
  @Prop({ default: false, type: Boolean }) flat!: boolean
  @Prop({ default: false, type: Boolean }) line!: boolean

  get classes() {
    return {
      _type: this.type,
      _flat: this.flat,
      _line: this.line,
    }
  }
}
