










































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Logo from '@/components/Logo.vue'
import CopyButton from '@/components/CopyButton.vue'
import Message from '@/components/Message.vue'

@Component({
  components: { Logo, CopyButton, Message },
  computed: mapState(['items']),
})
export default class More extends Vue {
  items!: any

  resetMessages() {
    this.importedItems = null
    this.demoItemsAdded = false
    this.itemsCleared = false
  }

  // Export

  get exportString() {
    return JSON.stringify(this.items)
  }

  onFocusExport(e: any) {
    e.target.select()
  }

  // Import

  importString: string = ''
  importedItems: number | null = null

  get existingIds() {
    return this.items.map((x: any) => x.id)
  }

  get importItems() {
    try {
      return JSON.parse(this.importString)
    } catch {
      return []
    }
  }

  get importableItems() {
    if (this.importItems.length) {
      return this.importItems.filter((x: any) => {
        const isDupe = this.existingIds.includes(x.id)
        const isTime = x.id && x.label && x.datetime && x.timezone
        return !isDupe && isTime
      })
    } else {
      return []
    }
  }

  get importFeedback() {
    switch (this.importedItems) {
      case -1:
        return 'Couldn’t read that, try copying again'
      case 0:
        return 'Nothing new to add!'
      case 1:
        return 'Added one new time'
      default:
        return `Added ${this.importedItems} new times`
    }
  }

  onSubmitImport() {
    this.resetMessages()
    if (this.importableItems.length) {
      this.importedItems = this.importableItems.length
      for (const item of this.importableItems) {
        this.$store.commit('addItem', item)
      }
    } else {
      this.importedItems = -1
    }
  }

  async onFocusImport() {
    if (!navigator.clipboard) {
      return
    }
    // if there’s clipboard data, get it
    const text = await navigator.clipboard.readText()
    // if it’s JSON, insert it
    try {
      const parse = JSON.parse(text)
      this.importString = text
    } catch (error) {
      // this is fine
    }
  }

  // Clear

  itemsCleared = false
  onClickClear() {
    if (confirm('You’ll never see these times again')) {
      this.$store.commit('removeItems')
      this.resetMessages()
      this.itemsCleared = true
    }
  }

  // Demo

  demoItemsAdded = false
  onClickDemo() {
    this.$store.commit('pushDemoItems')
    this.resetMessages()
    this.demoItemsAdded = true
  }
}
