

























import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class CopyButton extends Vue {
  @Prop({ type: String }) copy!: string
  @Ref('el') readonly button!: HTMLButtonElement

  labelTimeout: number = 1600
  copySuccess: boolean = false
  copyFail: boolean = false

  onCopySuccess() {
    this.copySuccess = true
    this.onCopyDone()
  }

  onCopyFail() {
    this.copyFail = true
    this.onCopyDone()
  }

  onCopyDone() {
    this.button.focus()
    setTimeout(() => {
      this.copySuccess = false
      this.copyFail = false
    }, this.labelTimeout)
  }
}
