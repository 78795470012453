



































import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Logo from '@/components/Logo.vue'
import TimeString from '@/components/TimeString.vue'

@Component({
  components: { Logo, TimeString },
  computed: mapState(['items']),
})
export default class Home extends Vue {
  items!: any

  get date() {
    return new Date()
  }

  get isWeekend() {
    const day = this.date.getDay()
    const hour = this.date.getHours()

    if (day < 1 || (day > 4 && hour > 16)) {
      return true
    }

    return false
  }

  get targetTime() {
    const t = this.date

    if (!this.isWeekend) {
      const friday = t.getDate() + ((12 - t.getDay()) % 7)
      t.setDate(friday)
      t.setHours(17, 0, 0, 0)
    } else {
      const monday = t.getDate() + ((8 - t.getDay()) % 7)
      t.setDate(monday)
      t.setHours(0, 0, 0, 0)
    }

    return t.toISOString()
  }
}
