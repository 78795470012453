


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import enGB from 'date-fns/locale/en-GB'
import { format, utcToZonedTime } from 'date-fns-tz'
import { TIMEZONES } from '@/timezones.ts'

@Component({
  computed: mapState(['user']),
})
export default class SelectTimezone extends Vue {
  @Prop() target?: string
  @Prop() value?: string

  user!: any

  get abbr() {
    const target = this.target ? new Date(this.target) : new Date()
    const timeZone = this.value || this.user.timezone
    const zonedTime = utcToZonedTime(target, timeZone)
    const zone = {
      timeZone,
      locale: enGB,
    }
    return format(zonedTime, 'zzz', zone)
  }

  get timezoneOptions() {
    return TIMEZONES
  }
}
